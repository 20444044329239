import React from "react";
import "./HomePage.scss";
import coffeeGif from "../../assets/gifs/roasting.gif";
import coffeeLogo from "../../assets/images/logo.png";

export default function HomePage() {
  return (
    <div className="homePage">
      <p className="homePage__text">
        Beans roasting... <br />
        Check back for more info
      </p>
      <section className="homePage__gif-wrapper">
        <img
          src={coffeeGif}
          alt="Coffee grounds stirring in a roaster."
          className="homePage__gif"
        />
      </section>
      <img
        src={coffeeLogo}
        alt="PleasantBrew Roasting Co."
        className="homePage__logo"
      />
      <section className="homePage__announce-wrapper">
        <p className="homePage__announce">coming soon!</p>
      </section>
    </div>
  );
}
